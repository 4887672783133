import React from 'react'

import { Button } from '@lumoslabs/lumosity-storybook'
import Link from 'next/link'

import useTrackLinkClick from '~/hooks/analytics/useTrackLinkClick'

interface ButtonLinkProps extends React.ComponentProps<typeof Button> {
  eventType?: 'button' | 'interstitial' | 'banner'
  eventText?: string
  eventLocation?: string
  href: string
}

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ href, eventType = 'interstitial', eventText, eventLocation, children, onClick, ...props }, ref) => {
    const track = useTrackLinkClick()
    const onClickWithTrack: typeof onClick = (...args) => {
      track({
        type: eventType,
        text: eventText || children?.toString() || '',
        destination: '',
        location: eventLocation,
      })
      return onClick ? onClick(...args) : undefined
    }

    return (
      <Link passHref href={href}>
        <Button {...props} as='a' onClick={onClickWithTrack} ref={ref}>
          {children}
        </Button>
      </Link>
    )
  },
)

export default ButtonLink
