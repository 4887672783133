const { missingKeys } = require('./src/utils/loggerUtils.js')

// TODO: once Settings page copy is finalized, move any strings from onboard into common
module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['areas', 'common', 'gameNamesAndDescriptions', 'onboard', 'game'], // TODO: remove 'game' here
    '/': ['index'],
    '/login': ['login'],
    '/fit-test': ['fit-test'],
    'rgx:^/game.*': ['game'],
    '/games': ['games'],
    '/onboard': ['onboard'],
    '/settings': ['settings'],
    '/stats': ['stats'],
    '/dev': ['index', 'common'],
  },
  logger: missingKeys,
}
