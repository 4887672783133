export const RANK_COLORS: Record<number, LumosColor> = {
  0: 'coolGray82',
  1: 'kaleBase',
  2: 'tealBase',
  3: 'orangeBase',
  4: 'tomatoBase',
  5: 'plumBase',
}

export const RANKS_KEYED_BY_NUMBER: Record<number | string, string> = {
  0: 'noBadges',
  1: 'newcomer',
  2: 'explorer',
  3: 'strategist',
  4: 'brainiac',
  5: 'mastermind',
} as const

export const RANKS_KEYED_BY_NAME: Record<string, number> = {
  newcomer: 1,
  explorer: 2,
  strategist: 3,
  brainiac: 4,
  mastermind: 5,
}

export const REQUIRED_PLAYS: Record<number | string, number> = {
  1: 3,
  2: 5,
  3: 10,
  4: 15,
  5: 20,
} as const

export const FOOTER_LINKS = {
  termsOfService: 'https://www.lumosity.com/terms_of_service',
  privacyPolicy: 'https://www.lumosity.com/privacy_policy',
  californiaPrivacy: 'https://www.lumosity.com/privacy_policy/#what-information-we-collect',
  help: 'https://help.lumosity.com',
} as const

export const DUMMY_USER_ID = '96b0cff4-d219-4d25-b38c-199ab279a591'

export enum AccountState {
  FREE = 'FREE',
  FREE_TRIAL_ELIGIBLE = 'FREE_TRIAL_ELIGIBLE',
  PREMIUM = 'PREMIUM',
}

export enum GameListFilter {
  byArea,
  byBadge,
}

export enum FitTestGame {
  'color-match' = 'color-match',
  'train-of-thought' = 'train-of-thought',
  'memory-matrix' = 'memory-matrix',
}

export type LumosUser = {
  id: string
  firstName: string
  birthdate: string
  authExpiration: number // in milliseconds since epoch
  accountStatus?: AccountState
}

export enum PlayContext {
  'workout',
  'freePlay',
  'fitTest',
}

export const LOGGED_OUT_SUBSCRIPTION_STATUS = AccountState.FREE

// uncertain whether these are all to be deprecated, but expectations are that these will be recategorized (or 'mapped')
export enum AreaSlugDeprecated {
  'language' = 'language',
  'math' = 'math',
  'speed' = 'speed',
}

export enum AreaSlugWithLPI {
  'attention' = 'attention',
  'flexibility' = 'flexibility',
  'memory' = 'memory',
  'problem-solving' = 'problem-solving',
}

export type AreaSlug = AreaSlugDeprecated | AreaSlugWithLPI

export const AreaSlug = { ...AreaSlugWithLPI, ...AreaSlugDeprecated }

export const PLATFORM_WEB = 'web'

export const LIMITED_WORKOUT_GAME_TOTAL = 3
export const PREMIUM_WORKOUT_GAME_TOTAL = 5

export enum HeaderTypes {
  lumos = 'lumos',
  singleGame = 'single-game',
  postWorkoutGame = 'postWorkoutGame',
  survey = 'survey',
  login = 'login',
  preWorkoutGame = 'preWorkoutGame',
  preFitTestGame = 'preFitTestGame',
  exitFlow = 'exit-flow',
  empty = 'empty',
}
