import { useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'

import { currentUserVar } from '~/reactive-vars'

export interface RouteGuardProps {
  children: JSX.Element
}

const RouteGuard = ({ children }: RouteGuardProps): JSX.Element => {
  const currentUser = useReactiveVar(currentUserVar)
  const router = useRouter()
  const currentPath = router.pathname
  const publicPaths = ['/login', '/author']
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    // does not perform the hook when currentUserVar is in its initial state
    if (currentUser && currentUser.authExpiration === 0) return

    if ((currentUser && currentUser.authExpiration >= Date.now()) || publicPaths.includes(currentPath)) {
      setIsAuthorized(true)
    } else {
      setIsAuthorized(false)
      router.push('/login')
    }
  }, [currentUser, currentPath])

  if (!isAuthorized) {
    return <></>
  }
  return children
}

export default RouteGuard
