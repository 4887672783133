import { useContext } from 'react'

import { Callback } from 'amplitude-js'

import { AnalyticsContext } from '~/context/AnalyticsContext'

type TrackResult = Parameters<Callback>
export default function useAnalytics() {
  const { client } = useContext(AnalyticsContext)

  const track = (eventName: string, eventData: Record<string, any> = {}) => {
    return new Promise<TrackResult>((resolve, _reject) => {
      if (client) {
        client?.logEvent(eventName, eventData, (responseCode, responseBody, details) => {
          resolve([responseCode, responseBody, details])
        })
      } else {
        console.log('Fallback track: ' + eventName, eventData)
        resolve([0, ''])
      }
    })
  }

  return track
}
