import { useEffect, useState } from 'react'

import getConfig from 'next/config'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()

export interface ResearchRouteGuardProps {
  children: JSX.Element
}

const ResearchRouteGuard = ({ children }: ResearchRouteGuardProps): JSX.Element => {
  const isResearch = publicRuntimeConfig.researchMode
  const router = useRouter()
  const currentPath = router.pathname
  const blockedPathsForResearch = ['/settings', '/stats', '/onboard', '/fit-test', '/games', '/subscribe']
  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (isResearch && !blockedPathsForResearch.includes(currentPath)) {
      setIsAuthorized(true)
    } else {
      setIsAuthorized(false)
      router.push('/')
    }
  }, [isResearch, currentPath])

  if (!isAuthorized) {
    return <></>
  }
  return children
}

export default ResearchRouteGuard
