import { useEffect } from 'react'

import { playGameSoundsVar, showSideNavVar, gameListFilterVar } from '~/graphql/reactive-vars'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import { parseUser } from '~/utils/loginUtils'

/**
 * Handles changes to persisted client state
 * @param storageEvent Emitted on changes to localStorage
 */
const storageHandler = (storageEvent: StorageEvent) => {
  if (storageEvent.key === 'game_sound_on') {
    const { newValue } = storageEvent
    const parsedNewValue = JSON.parse(newValue || 'null')
    newValue && playGameSoundsVar(parsedNewValue)
  }
  if (storageEvent.key === 'show_side_nav') {
    console.log('changing side nav state')
    const { newValue } = storageEvent
    const parsedNewValue = JSON.parse(newValue || 'null')
    newValue && showSideNavVar(parsedNewValue)
  }
  if (storageEvent.key === 'game_list_filter') {
    const { newValue } = storageEvent
    const parsedNewValue = JSON.parse(newValue || 'null')
    newValue && gameListFilterVar(parsedNewValue)
  }
}

const usePersistedClientConfig = (cookie: Record<string, string>): void => {
  useEffect(() => {
    const lastUser = parseUser(cookie.lumosUser) ?? undefined
    currentUserVar(lastUser)

    // reactiveVariables that are persisted must be initialized
    // from localStorage on the client
    // ...UI settings could also be grouped into a complex object stored as one entry
    // 'null' fallback to happify Typescript
    const playGameSounds = JSON.parse(localStorage.getItem('game_sound_on') ?? 'null')
    const showSideNav = JSON.parse(localStorage.getItem('show_side_nav') ?? 'null')
    const gameListFilter = JSON.parse(localStorage.getItem('game_list_filter') ?? 'null')
    // avoid overwriting initial values with NULL
    playGameSounds !== null && playGameSoundsVar(playGameSounds)
    showSideNav !== null && showSideNavVar(showSideNav)
    gameListFilter !== null && gameListFilterVar(gameListFilter)

    window.addEventListener('storage', storageHandler)
    return () => window.removeEventListener('storage', storageHandler)
  }, [cookie.lumosUser])
}

export default usePersistedClientConfig
