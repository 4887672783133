import { useEffect, useState } from 'react'

import useTranslation from 'next-translate/useTranslation'

const useLocale = () => {
  useEffect(() => {
    setNavLanguage(navigator?.language)
    setNavLanguageSet(true)
  }, [])
  const [navLanguage, setNavLanguage] = useState('en-us')
  const [navLanguageSet, setNavLanguageSet] = useState(false)

  const { lang } = useTranslation()

  // Check if navLanguage is a supported language
  const isSupported = (navLanguage: string) => {
    return /^en|fr|pt|de|es|ja|ko/.test(navLanguage)
  }

  // TODO: when we add support for other languages, switch to this method for locale
  const supportedLanguage = isSupported(navLanguage) ? navLanguage : lang

  // If browser language is English, get their browser preferred EN version (en-GB for example), otherwise use the site locale
  const locale: string = navLanguage.startsWith('en') ? navLanguage : lang

  return { locale, supportedLanguage, navLanguageSet }
}

export default useLocale
