import { useCookies } from 'react-cookie'

import usePersistedClientConfig from '~/hooks/usePersistedClientValues'

interface AppWrapperProps {
  children: React.ReactNode
}

/**
 * Use AppWrapper to handle client state initialization
 */
export function AppWrapper({ children }: AppWrapperProps) {
  const [cookie] = useCookies(['lumosUser'])

  // read local client values and set client state
  usePersistedClientConfig(cookie)

  return <>{children}</>
}
