import { makeVar, ReactiveVar } from '@apollo/client'

import type { LumosUser } from '~/constants'

const userInitialValue: LumosUser = {
  id: '',
  firstName: '',
  birthdate: '',
  authExpiration: 0,
}

export const currentUserVar: ReactiveVar<LumosUser> = makeVar<LumosUser>(userInitialValue)
