import { AnalyticsEvents } from '~/context/AnalyticsContext'
import useAnalytics from '~/hooks/analytics/useAnalytics'
import useCurrentScreen from '~/hooks/analytics/useCurrentScreen'

export interface TrackLinkData {
  type: 'button' | 'interstitial' | 'banner' //the type of CTA {button, interstitial, banner}
  text: string //the text/name of the CTA
  destination: string //generally a URL destination of clicking the CTA
  location?: string //if possible, the location of the button (e.g. module name)
}

export default function useTrackLinkClick() {
  const [currentScreen] = useCurrentScreen()
  const track = useAnalytics()
  const onClick = ({ location, ...data }: TrackLinkData) => {
    track(AnalyticsEvents.CTAClick, {
      page_url: window.location.href, //what url is the CTA button on
      location: location || currentScreen || '',
      ...data,
    })
  }

  return onClick
}
