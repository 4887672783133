import React from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import Link from 'next/link'
import styled from 'styled-components'

import PageContent from '~/components/ui/PageContent'
import BrandTitle from '~/images/brand_title.svg'

const { Heading3, Subheading2 } = fonts

const MobileDevicesInterstitialResearch = (): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <>
      <Head>
        <title>{t('interstitials.mobileDevicesResearch.title')}</title>
      </Head>
      <SimpleTopNav>
        <Link href='/' passHref>
          <a href='replace'>
            <BrandTitle title='Lumosity' width={122} height={31} aria-labelledby='lumosity' />
          </a>
        </Link>
      </SimpleTopNav>
      <PageContent>
        <PageContent.Header>
          <Heading3 as='h3'>{t('interstitials.mobileDevicesResearch.header')}</Heading3>
        </PageContent.Header>
        <PageContent.Main>
          <CaptionContainer>
            <Subheading2>{t('interstitials.mobileDevicesResearch.body')}</Subheading2>
          </CaptionContainer>
        </PageContent.Main>
      </PageContent>
    </>
  )
}

const CaptionContainer = styled.div`
  margin-top: 16px;
`

const SimpleTopNav = styled.div`
  padding: 0px 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  background-color: ${({ theme }) => theme.colors.inkBase};
`

export default MobileDevicesInterstitialResearch
