import { DefaultTheme } from '@lumoslabs/lumosity-storybook'

import * as layout from '~/styles/layout'

const theme = {
  ...DefaultTheme,
  layout: layout,
  mediaQuery: layout.mediaQuery,
  animation: {
    durationBase: '0.25s',
  },
  card: {
    shadow: '0px 0px 4px 2px rgba(14, 35, 55, 0.1)',
  },
}

export default theme
