import { keyBy } from 'lodash'

import { AreaSlug } from '~/constants'

const GamesLibrary: Array<GameConfig> = [
  {
    slug: 'brain-shift',
    id: 'de23e83f-59ec-4338-a727-f88331cdb819',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Brain-Shift.svg',
    heroImage: '/assets/images/games/brain-shift-web-hero.svg',
    assetsPath: 'BrainShift_CC/web/3.0.2_2022_06_08/c370fd2c5eef171d77b4cd7be6dc886563f0053e/558616247/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'disillusion',
    id: 'cb129d45-fac4-4fe5-bcd1-75d8b9ccc67d',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Disillusion.svg',
    heroImage: '/assets/images/games/disillusion-web-hero.svg',
    assetsPath: 'Disillusion_CC/web/3.0.5_2022_06_01/e6cc45072ab5e98e7431a0d3e365935781b9d612/553167559/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'eagle-eye',
    id: '93ba9128-2e52-43c9-aec8-5d4c1c2af287',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Eagle-Eye.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'EagleEye_CC/web/3.0.9_2022_08_10/c77c035180b73cd12b3cc316530b5974afb322da/608960991/release',
    bestStatLabelKey: 'unknown',
    isStatLabelFirst: false,
  },
  {
    slug: 'ebb-and-flow',
    id: 'e4250024-805d-43d3-93f0-9352b1621117',
    areaSlug: AreaSlug.flexibility,
    gamesPageImage: '/assets/images/games/Game-Icon-Ebb-And-Flow.svg',
    heroImage: '/assets/images/games/ebb-and-flow-web-hero.svg',
    assetsPath: 'EbbAndFlow_CC/master/75878eaa983acbffccc565fa8363a41f55a1e74c/183325132/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'feel-the-beat',
    id: 'd083bcd3-097b-42de-bdc8-cfb14a8f0eaa',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Feel-The-Beat.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'RhythmRage_CC/web/1.1.6_2021_05_28/621eeab8a85b57bd570bf93f09f72ae1a3a5977d/311300556/release',
    bestStatLabelKey: 'beat-streak',
    isStatLabelFirst: false,
  },
  {
    slug: 'organic-order',
    id: '63d01274-12c4-4130-ae7b-5e43bed34cef',
    areaSlug: AreaSlug['problem-solving'],
    gamesPageImage: '/assets/images/games/Game-Icon-Organic-Order.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'OrganicOrder_CC/master/02621dd21bb84e53afa87118d97f6f04179397a3/241847604/release',
    bestStatLabelKey: 'unknown',
    isStatLabelFirst: true,
  },
  {
    slug: 'penguin-pursuit',
    id: '49298e56-6d41-43f2-91aa-4b78316d91bd',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-Penguin-Pursuit.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath:
      'PenguinPursuit_CC/PenguinPursuit_CC/web/3.0.3_2021_03_31/618cf2634409e58ffe4440adf2900e7e3c12209b/279684926/release',
    bestStatLabelKey: 'unknown',
    isStatLabelFirst: true,
  },
  {
    slug: 'raindrops',
    id: '22d72145-6f5c-4f4c-9a30-61030d80e10b',
    areaSlug: AreaSlug.math,
    gamesPageImage: '/assets/images/games/Game-Icon-Raindrops.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'Raindrops_CC/web/3.1.4_2021_10_27/4af13177454056c0452a9474d89a961f7a3e3ce0/396650873/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
  {
    slug: 'river-ranger',
    id: '82e3b087-1d2c-469e-a9ad-eb317a30a8da',
    areaSlug: AreaSlug.speed,
    gamesPageImage: '/assets/images/games/Game-Icon-River-Ranger.svg',
    heroImage: '/assets/images/games/river-ranger-web-hero.svg',
    assetsPath: 'RiverRanger_CC/web/3.1.4_2022_01_05/d7f7e30b87e8b031bd2654d297bb3b621a67e476/441904900/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
  },
  {
    slug: 'star-search',
    id: 'a47fbba8-111e-4720-887f-2f9efe09fd05',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Star-Search.svg',
    heroImage: '/assets/images/default-hero.svg',
    assetsPath: 'StarSearch_CC/web/3.0.5_2022_05_25/e6009b64a2c933240085e34624b50fecb861e381/547850780/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
  },
  {
    slug: 'train-of-thought',
    id: '3564ab29-642f-4acc-9ee2-c7e3ac552d33',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Train-Of-Thought.svg',
    heroImage: '/assets/images/games/train-of-thought-web-hero.svg',
    assetsPath: 'TrainOfThought_CC/web/3.0.9_2021_09_15/9fc82dd6516dec9fd8c8cd8ae13b2118bd657d06/371167854/release',
    bestStatLabelKey: 'trains',
    isStatLabelFirst: false,
  },
  {
    slug: 'trouble-brewing',
    id: '17b5f376-5ac4-44e7-8e80-508201e62435',
    areaSlug: AreaSlug.attention,
    gamesPageImage: '/assets/images/games/Game-Icon-Trouble-Brewing.svg',
    heroImage: '/assets/images/games/trouble-brewing-web-hero.svg',
    assetsPath: 'TroubleBrewing_CC/web/3.1.3_2021_12_07/8cff16085e23d47a0f6a32a62ae7ffb96695a21e/424297560/release',
    bestStatLabelKey: 'correct',
    isStatLabelFirst: false,
  },
]

export const GamesLibraryDictionary = keyBy(GamesLibrary, 'slug')

export const allGameSlugs: GameSlug[] = GamesLibrary.map((game) => game.slug)

// Add any games that shouldn't be included in workouts here
export const unplayableGameSlugs: GameSlug[] = []

// Use this list for workout queries until the issue above is resolved
export const playableGameSlugs: GameSlug[] = allGameSlugs.filter((slug) => !unplayableGameSlugs.includes(slug))
/**********************************************************************/

export default GamesLibrary
