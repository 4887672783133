import { BasicQueryStringUtils, LocationLike } from '@openid/appauth'
import cookie from 'cookie'
import { NextPageContext } from 'next'
import getConfig from 'next/config'

import { LumosUser, AccountState } from '~/constants'

const { publicRuntimeConfig } = getConfig()

export class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike, useHash?: boolean) {
    useHash = false /* never use hash */
    return super.parse(input, useHash)
  }
}

export const getJWTPayload = (token: string | undefined): any | null => {
  // use this to get your token quickly!
  // console.log(token)
  if (!token) return null
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_, userInfo] = token.split('.')
  const payload = Buffer.from(userInfo, 'base64')
  return JSON.parse(payload.toString())
}

export const parseUser = (token: string | undefined | null): LumosUser | null => {
  if (!token) return null
  const isResearch = publicRuntimeConfig.researchMode

  let name
  let birthday
  let subFromJwt
  let expiration

  if (isResearch) {
    const { sub, username, exp } = getJWTPayload(token)
    name = username || 'Please try logging out and back in'
    birthday = '1980-01-02'
    subFromJwt = sub
    expiration = exp
  } else {
    const { sub, first_name, birthdate, exp } = getJWTPayload(token)
    name = first_name
    birthday = birthdate
    subFromJwt = sub
    expiration = exp
  }

  return {
    id: subFromJwt.split('|')[1],
    firstName: name,
    birthdate: birthday,
    authExpiration: isResearch ? new Date('2090-02-01').valueOf() : new Date(expiration * 1000).valueOf(),
    accountStatus: AccountState.FREE,
  }
}

export const readStream = async (stream: ReadableStream<Uint8Array> | null): Promise<Uint8Array[]> => {
  if (!stream) return [] as Uint8Array[]
  const reader = stream.getReader()
  const chunks = [] as Uint8Array[]

  let ended
  while (!ended) {
    const { done, value } = await reader.read()
    ended = done
    if (done) {
      return chunks
    }
    chunks.push(value as Uint8Array)
  }
  return [] as Uint8Array[]
}

export function parseCookies(req: NextPageContext['req']) {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}
